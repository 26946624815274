import "./vision.css";

import { visionTitle, visionDeascription } from "./text";

import SectionsCard from "../../Cards/SectionsCard";
import Reveal from "../../utils/Reveal";

import robot from "../../../assets/images/robotic.jpg";

const Vision = () => {
  return (
    <div className="container vision-section">
      <div className="row d-flex flex-wrap">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="row d-flex flex-wrap">
            <div className="col-sm-12 col-lg-6">
              <Reveal animationType="fadeInRight">
                <SectionsCard
                  title={visionTitle}
                  description={visionDeascription}
                  className="vision-card"
                  svgChild={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1440 320"
                      className="svg-addition"
                    >
                      <path
                        fill="#4472c4"
                        fill-opacity="1"
                        d="M0,128L60,149.3C120,171,240,213,360,208C480,203,600,149,720,128C840,107,960,117,1080,112C1200,107,1320,85,1380,74.7L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                      ></path>
                    </svg>
                  }
                />
              </Reveal>
            </div>
            <div className="col-sm-12 col-lg-6 z-2">
              <div className="vision-image">
                <img src={robot} alt="" className="rounded w-100" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default Vision;
