import "./brandingHeader.css";

import { Parallax } from "react-parallax";

import clouds from "../../../assets/images/clouds.jpg";
import logo from "../../../assets/images/IPGlogo.png";

const BrandingHeader = () => {
  return (
    <div className="branding-section w-100">
      <Parallax bgImage={clouds} strength={500}>
        <div className="container">
          <div className="row d-flex flex-wrap">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img src={logo} alt="" className="w-50"></img>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="branding-title">
                <h3 className="text-light">
                  Limitless Creations. Fuelled With Passion
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default BrandingHeader;
