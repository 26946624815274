export const missionTitle = "Mission";

export const missionDescription = `
We are providing complete turn-key
solutions within aircraft, electronics,
smart, phone , computer equipment’s
for the international aviation. We focus
on the niches where we can make a
difference, and we always strive to
deliver solutions rather than products.
We build on partnerships expanding
our capabilities and international
presence, and we invest in the
development of our company and
employees, to continue to deliver 
`;
