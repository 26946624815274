import "./footer.css";

import logo from "../../../assets/images/IPGlogo.png";

const Footer = () => {
  return (
    <div className="footer container d-flex flex-column flex-md-row justify-content-md-around align-items-md-center pb-3">
      <div className="logo mb-3 mb-md-0">
        <div className="footer-logo">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="text-center">
        <h1 className="footer-title mt-4 fs-1">
          <span className="first-part text-decoration-underline">
            TRADING F
          </span>
          <span className="second-part text-decoration-underline">ZCO</span>
        </h1>
        <div className="footer-address">
          <p className="text-light text-start fw-bold mb-0">
            Office-6 GRG Business Center
          </p>
          <p className="text-light text-start fw-bold mb-0">
            First floor Oud Metha Bdlg
          </p>
          <p className="text-light text-start fw-bold mb-0">Dubai - UAE</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
