import "./sectionsCard.css";

const SectionsCard = ({ title, description, className, svgChild }) => {
  return (
    <div className="section-card">
      <div className={className}>
        <div className="text-left">
          <h6 className="text-light fs-4">{title}</h6>
          <p className="text-light">{description}</p>
        </div>
      </div>
      <div>{svgChild}</div>
    </div>
  );
};

export default SectionsCard;
