import "./tradingHeader.css";

import logo from "../../../assets/images/IPGlogo.png";
import Reveal from "../../utils/Reveal";

const TradingHeader = () => {
  return (
    <div className="trading-banner">
      <div className="container">
        <Reveal animationType="fadeInUp">
          <div className="row justify-content-center trading-header pb-5 pt-3">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <img src={logo} alt=""></img>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <h1 className="trading-title mt-4">
                <span className="first-part text-decoration-underline">
                  TRADING F
                </span>
                <span className="second-part text-decoration-underline">
                  ZCO
                </span>
              </h1>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default TradingHeader;
