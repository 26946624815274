export const computerElements = [
  "Central processing unit (CPU)",
  "Motherboard, micro chip",
  "Random Access Memory (RAM)",
  "Video graphics array",
  "Power supply",
  "Cooling fan",
  "Hard drive",
  "Monitor",
  "Smart phones and accessories",
];
