import "./credentials.css";

import Reveal from "../../utils/Reveal";

import certeficate from "../../../assets/images/certeficate.png";
import license from "../../../assets/images/license.png";

const Credentials = () => {
  const certeficateURL = "/certeficatePDF.pdf";
  const licenseURL = "/licensePDF.pdf";
  const dubaiCivilURL = "/dybai_civil.jpeg";

  return (
    <div className="container credentials background-light d-flex flex-column flex-md-row justify-content-md-evenly align-items-md-center pt-4 pb-5">
      <div className="credentials-container d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="certeficate-container text-center ms-md-5">
          <Reveal animationType="fadeInRight">
            <div className="certeficate">
              <a
                href={certeficateURL}
                target="_blank"
                rel="noopener noreferrer"
                className="credentials-link order-1 order-md-0 mt-md-4"
              >
                <h3 className="certeficate-title">Certificate of Formation</h3>
                <img
                  src={certeficate}
                  alt=""
                  className="certeficate-img img-fluid w-25 rounded"
                />
              </a>
            </div>
          </Reveal>
        </div>
        <div className="license-container text-center me-md-4">
          <Reveal animationType="fadeInLeft">
            <div className="license">
              <a
                href={dubaiCivilURL}
                target="_blank"
                rel="noopener noreferrer"
                className="credentials-link order-2 order-md-0 mt-md-5"
              >
                <h3 className="license-title">
                  Dubai Civil Aviation Authority
                </h3>
                <img
                  src={dubaiCivilURL}
                  alt=""
                  className="license-img img-fluid w-25 rounded"
                />
              </a>
            </div>
          </Reveal>
        </div>
        <div className="license-container text-center me-md-4">
          <Reveal animationType="fadeInLeft">
            <div className="license">
              <a
                href={licenseURL}
                target="_blank"
                rel="noopener noreferrer"
                className="credentials-link order-2 order-md-0 mt-md-5"
              >
                <h3 className="license-title">Trade License</h3>
                <img
                  src={license}
                  alt=""
                  className="license-img img-fluid w-25 rounded"
                />
              </a>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default Credentials;
