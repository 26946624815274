import "./map.css";

const Map = () => {
  return (
    <div className="container mw-100 background-light map-container">
      <div className="row">
        <div className="col-12 gx-0">
          <div className="map">
            <iframe
              title="companyMap"
              className="w-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.29579801396!2d55.31972497459718!3d25.226960230496065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d6003c05b05%3A0xbadbc2670af0a70e!2sGRG%20Business%20Centers!5e0!3m2!1sen!2sam!4v1690803716960!5m2!1sen!2sam"
              height="450"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
