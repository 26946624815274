export const aboutTitle = "About Us";

export const aboutDescription = `
We are startup company aiming to lead
international business industries involved smart
phone , computer equipment’s , aircraft spare
parts providing “turn-key” supply to companies
operating in GCC and MENSA regions.

Delivering cost-effective products throughout the
GCC and Middle East, smart phone and
accessories trading, computer equipment’s and
products.
`;
