import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";

import { Helmet } from "react-helmet";

import ComputerTechnology from "./components/sections/ComputerTechnology/ComputerTechnology";
import AircraftTechnology from "./components/sections/AircraftTechnology/AircraftTechnology";
import BrandingHeader from "./components/sections/BrandingHeader/BrandingHeader";
import TradingHeader from "./components/sections/TradingHeader/TradingHeader";
import Credentials from "./components/sections/Credentials/Credentials";
import HomeBanner from "./components/sections/Home/HomeBanner";
import Mission from "./components/sections/Mission/Mission";
import Footer from "./components/sections/Footer/Footer";
import Vision from "./components/sections/Vision/Vision";
import About from "./components/sections/About/About";
import Map from "./components/sections/Map/Map";

import logo from "./assets/images/IPGlogo.png";

function App() {
  return (
    <div className="App">
      <Helmet>
        <link rel="icon" href={logo} type="image/png"></link>
      </Helmet>
      <HomeBanner />
      <TradingHeader />
      <div className="main-sections pt-5">
        <About />
        <Mission />
        <Vision />
      </div>
      <Credentials />
      <div className="computer-aircraft-components">
        <ComputerTechnology />
        <AircraftTechnology />
      </div>
      <BrandingHeader />
      <Map />
      <Footer />
    </div>
  );
}

export default App;
