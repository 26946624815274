import "./mission.css";

import { missionTitle, missionDescription } from "./text";

import SectionsCard from "../../Cards/SectionsCard";
import Reveal from "../../utils/Reveal";

import dubaiSkyscrapers from "../../../assets/images/dubaiSkyscrapers.jpg";

const Mission = () => {
  return (
    <div className="container mission-section">
      <div className="row d-flex flex-wrap">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="row d-flex flex-wrap">
            <div className="col-sm-12 col-lg-6 order-2 order-md-1">
              <img
                src={dubaiSkyscrapers}
                alt=""
                className="w-100 rounded img-fluid"
              />
            </div>
            <div className="col-sm-12 col-lg-6 order-1 order-md-2 mission-description">
              <Reveal animationType="fadeInLeft">
                <SectionsCard
                  title={missionTitle}
                  description={missionDescription}
                  className="mission-card"
                />
              </Reveal>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default Mission;
