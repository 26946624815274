import "./about.css";

import { aboutTitle, aboutDescription } from "./text";

import SectionsCard from "../../Cards/SectionsCard";
import Reveal from "../../utils/Reveal";

import aircraft from "../../../assets/images/aircraft.png";

const About = () => {
  return (
    <div className="container about-section">
      <div className="row d-flex flex-wrap">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="row d-flex flex-wrap">
            <div className="col-sm-12 col-lg-6 about-us">
              <Reveal animationType="fadeInRight">
                <SectionsCard
                  title={aboutTitle}
                  description={aboutDescription}
                  className="about-card"
                />
              </Reveal>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="about-image">
                <img src={aircraft} alt="" className="rounded img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default About;
