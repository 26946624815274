import "./home.css";

import { Parallax } from "react-parallax";

import parallaxImage from "../../../assets/images/dubaiClouds.webp";
import logo from "../../../assets/images/IPGlogo.png";

const HomeBanner = () => {
  return (
    <section className="banner background-light">
      <Parallax bgImage={parallaxImage} strength={500} className="parallax-img">
        <div className="container">
          <div className="home-banner-logo d-flex justify-content-end">
            <img src={logo} alt="" className="w-25 img-fluid home-banner-img" />
          </div>
        </div>
        <div
          className="container-fluid blue-banner"
          style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
        >
          <div className="row">
            <div className="col-12">
              <div className="banner-addition w-100">
                <div className="banner-header text-center">
                  <h3 className="text-light fs-2">
                    Limitless Creations. Fuelled with Passion.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  );
};

export default HomeBanner;
