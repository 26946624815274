import "./aircraftTechnology.css";

import { aircraftComponents } from "./text";

import aviationParts from "../../../assets/images/aviationParts.jpg";

const AircraftTechnology = () => {
  return (
    <div className="container pt-5">
      <div className="row d-flex flex-wrap">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="aviation-parts-container">
                <div className="aviation-parts-image">
                  <img src={aviationParts} alt="" className="rounded" />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="aircraft-lists pt-3">
                <h5 className="text-light fs-3">
                  Aircraft spare parts component
                </h5>
                <ul className="text-light">
                  {aircraftComponents.map((element, index) => (
                    <li key={index} className="pb-2">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a href="#" className="text-light">
                        {element}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default AircraftTechnology;
