import "./computerTechnology.css";

import { computerElements } from "./text";

import computerHardware from "../../../assets/images/computerHardware.jpg";

const ComputerTechnology = () => {
  return (
    <div className="container pt-5">
      <div className="row d-flex flex-wrap">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="computer-aircraft-el-lists rounded">
                <div className="computer-lists">
                  <h5 className="text-light fs-3">Computer Equipment's</h5>
                  <ul className="text-light">
                    {computerElements.map((element, index) => (
                      <li key={index} className="pb-2">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#" className="text-light">
                          {element}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="computer-hardware-container">
                <div className="computer-hardware-image">
                  <img src={computerHardware} alt="" className="rounded" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-1"></div>
    </div>
  );
};

export default ComputerTechnology;
